import React from 'react';
import ReactPlayer from 'react-player';

interface PlayerProps {
	url: string;
}

const Player: React.FC<PlayerProps> = ({ url }) => {
	return (
		<ReactPlayer
			url={url}
			playing={true}
			controls={true}
			width='100%'
			height='100%'
		/>
	);
};

export default Player;
