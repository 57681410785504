import React, { useEffect, useState } from 'react';
import ChannelList from './components/ChannelList';
import Player from './components/Player';
import { parseM3U } from './utils/parseM3U';

const App: React.FC = () => {
	const [channels, setChannels] = useState<{ name: string; url: string }[]>([]);
	const [currentUrl, setCurrentUrl] = useState<string>('');

	const saveLastPlayedChannel = (url: string) => {
		localStorage.setItem('lastPlayedChannel', url);
	};

	const loadLastPlayedChannel = () => {
		return localStorage.getItem('lastPlayedChannel');
	};

	useEffect(() => {
		const m3uContent = `
#EXTINF:-1,ПЕРЕЦ
https://cdn1.skygo.mn/live/disk1/Che/HLS-FTA/Che.m3u8
#EXTINF:-1,Fox  
https://stream01.vnet.am/Fox/mono.m3u8
#EXTINF:-1 group-title="Usba TV",Украина - Фильмы и сериалы
https://fl1001.bozztv.com/ushba5/tracks-v1a1/mono.m3u8
#EXTINF:-1 group-title="Usba TV",Украина 2 - Фильмы и сериалы
https://fl1001.bozztv.com/ushba12/tracks-v1a1/mono.m3u8		
#EXTINF:-1 group-title="Usba TV",Документалка 1 
https://fl1001.bozztv.com/ushba28/tracks-v1a1/mono.m3u8
#EXTINF:-1 group-title="Usba TV",Документалка 2
https://fl1001.bozztv.com/ushba29/tracks-v1a1/mono.m3u8
#EXTINF:-1 tvg-name="PersikTV ДОКУМЕНТАЛЬНОЕ" group-title="ПЕРСИК ТВ",PersikTV ДОКУМЕНТАЛЬНОЕ HD
https://fs2.persik.by/Dokumentalnoe3/index.m3u8
#EXTINF:-1 tvg-name="PersikTV ДОКУМЕНТАЛЬНОЕ КИНО" group-title="ПЕРСИК ТВ",PersikTV ДОКУМЕНТАЛЬНОЕ КИНО HD
https://fs2.persik.by/DokumentalnoeKino3/index.m3u8
#EXTINF:-1 group-title="Usba TV", Старые фильмы 1
https://fl1001.bozztv.com/ushba-rvisionkinopanorama/tracks-v1a1/mono.m3u8
#EXTINF:-1 group-title="Usba TV",Старые фильмы 2
https://fl1001.bozztv.com/ushba-rvisionmovies/tracks-v1a1/mono.m3u8
#EXTINF:-1 group-title="Usba TV",Старые фильмы 3
https://fl1001.bozztv.com/ushba-rvisionclassic/tracks-v1a1/mono.m3u8
#EXTINF:-1,Кино Драма - Сити Эдем
https://v2.catcast.tv/content/45269/index.m3u8
#EXTINF:-1,Кино Экшен - Сити Эдем
https://autopilot.catcast.tv/content/41333/index.m3u8
#EXTINF:-1,Кино Мистика - Сити Эдем
https://autopilot.catcast.tv/content/40783/index.m3u8
#EXTINF:-1,Кино Детектив - Сити Эдем
https://autopilot.catcast.tv/content/41327/index.m3u8
#EXTINF:-1 group-title="Usba TV",Кинозал 1
https://fl1001.bozztv.com/ushba13/tracks-v1a1/mono.m3u8
#EXTINF:-1 group-title="Usba TV",Кинозал 2
https://fl1001.bozztv.com/ushba14/tracks-v1a1/mono.m3u8
#EXTINF:-1 group-title="Usba TV",Кинозал 3
https://fl1001.bozztv.com/ushba24/tracks-v1a1/mono.m3u8
#EXTINF:-1 group-title="Usba TV",Кинозал 4
https://fl1001.bozztv.com/ushba26/tracks-v1a1/mono.m3u8
#EXTINF:-1 group-title="Usba TV",Кинозал 5
https://fl1001.bozztv.com/ushba-films-b/tracks-v1a1/mono.m3u8
#EXTINF:-1 group-title="Usba TV",Кинозал 6
https://fl1001.bozztv.com/ushba-films-c/tracks-v1a1/mono.m3u8
#EXTINF:-1 group-title="Usba TV",Кинозал 7
https://fl1001.bozztv.com/ushba-films-d/tracks-v1a1/mono.m3u8
#EXTINF:-1 group-title="Usba TV",Кинозал 8
https://fl1001.bozztv.com/ushba-films-e/tracks-v1a1/mono.m3u8
#EXTINF:-1 group-title="Usba TV",Кинозал 9
https://fl1001.bozztv.com/ushba-films-f/tracks-v1a1/mono.m3u8
#EXTINF:-1 group-title="Usba TV",Кинозал 10
https://fl1001.bozztv.com/ushba-films-g/tracks-v1a1/mono.m3u8
#EXTINF:-1 group-title="Usba TV",Кинозал 11
https://fl1001.bozztv.com/ushba-films-h/tracks-v1a1/mono.m3u8
#EXTINF:-1 group-title="Usba TV",Кинозал 12
https://fl1001.bozztv.com/ushba-films-i/tracks-v1a1/mono.m3u8
#EXTINF:-1 group-title="Usba TV",Кинозал 13
https://fl1001.bozztv.com/ushba-films-j/index.m3u8
#EXTINF:-1 group-title="Usba TV",Кинозал 14
https://fl1001.bozztv.com/ushba-films-m/tracks-v1a1/mono.m3u8
#EXTINF:-1 group-title="Usba TV",Кинозал 15
https://fl1001.bozztv.com/ushba-films-p/tracks-v1a1/mono.m3u8
#EXTINF:-1 group-title="Usba TV",Кинозал 16
https://fl1001.bozztv.com/ushba-films-t/tracks-v1a1/mono.m3u8
#EXTINF:-1 group-title="Usba TV",Кинозал 17
https://fl1001.bozztv.com/ushba-films-yz/tracks-v1a1/mono.m3u8
#EXTINF:-1 group-title="Usba TV",Кинозал 18
https://fl1001.bozztv.com/ushba-films-n/tracks-v1a1/mono.m3u8
#EXTINF:-1 group-title="Usba TV",Кинозал 19
https://fl1001.bozztv.com/ushba-films-qr/tracks-v1a1/mono.m3u8
#EXTINF:-1 group-title="Usba TV",Кинозал 20
https://fl1001.bozztv.com/ushba-films-uv/tracks-v1a1/mono.m3u8
#EXTINF:-1 group-title="Usba TV",Кинозал 21
https://fl1001.bozztv.com/ushba-films-o/index.m3u8
#EXTINF:-1 group-title="Usba TV",Кинозал 22
https://fl1001.bozztv.com/ushba-films-s/index.m3u8
#EXTINF:-1 group-title="Usba TV",Кинозал 23
https://fl1001.bozztv.com/ushba-films-w/index.m3u8
#EXTINF:-1 group-title="Usba TV",Кинозал 24
https://fl1001.bozztv.com/ushba-rfilrms-0/tracks-v1a1/mono.m3u8
#EXTINF:-1 group-title="Usba TV",Кинозал 25
https://fl1001.bozztv.com/ushba-rfilrms-a/tracks-v1a1/mono.m3u8
#EXTINF:-1 group-title="Usba TV",Кинозал 26
https://fl1001.bozztv.com/ushba-rfilrms-b/index.m3u8
#EXTINF:-1 group-title="Usba TV",Кинозал 27
https://fl1001.bozztv.com/ushba-rfilrms-c/tracks-v1a1/mono.m3u8
#EXTINF:-1 group-title="Usba TV",Кинозал 28
https://fl1001.bozztv.com/ushba-rfilrms-d/tracks-v1a1/mono.m3u8
#EXTINF:-1 group-title="Usba TV",Кинозал 29
https://fl1001.bozztv.com/ushba-rfilrms-e/tracks-v1a1/mono.m3u8
#EXTINF:-1 group-title="Usba TV",Кинозал 30
https://fl1001.bozztv.com/ushba-rfilrms-g/tracks-v1a1/mono.m3u8
#EXTINF:-1 group-title="Usba TV",Кинозал 31
https://fl1001.bozztv.com/ushba-rfilrms-i/tracks-v1a1/mono.m3u8
#EXTINF:-1 group-title="Usba TV",Кинозал 32
https://fl1001.bozztv.com/ushba-rfilrms-k/index.m3u8
#EXTINF:-1 group-title="Usba TV",Кинозал 33
https://fl1001.bozztv.com/ushba-rfilrms-p/tracks-v1a1/mono.m3u8
#EXTINF:-1 group-title="Usba TV",Кинозал 34
https://fl1001.bozztv.com/ushba-rfilrms-v/tracks-v1a1/mono.m3u8
#EXTINF:-1 group-title="Usba TV",Кинозал 35
https://fl1001.bozztv.com/ushba-rfilrms-z/tracks-v1a1/mono.m3u8
#EXTINF:-1 group-title="Usba TV",Кинозал 36
https://fl1001.bozztv.com/ushba64/index.m3u8
#EXTINF:-1 group-title="Usba TV",Кинозал 37
https://fl1001.bozztv.com/ushba65/index.m3u8

    `;

		const parsedChannels = parseM3U(m3uContent);
		setChannels(parsedChannels);

		const lastPlayedChannel = loadLastPlayedChannel();
		if (
			lastPlayedChannel &&
			parsedChannels.some(channel => channel.url === lastPlayedChannel)
		) {
			setCurrentUrl(lastPlayedChannel);
		} else if (parsedChannels.length > 0) {
			setCurrentUrl(parsedChannels[0].url);
			saveLastPlayedChannel(parsedChannels[0].url); // Сохраняем первый канал как последний воспроизведенный
		}
	}, []);

	return (
		<div className='container'>
			<div className='player'>
				<Player url={currentUrl} />
			</div>
			<div className='playlist'>
				<ChannelList channels={channels} onChannelClick={setCurrentUrl} />
			</div>
		</div>
	);
};

export default App;
