import { Parser } from 'm3u8-parser';

export const parseM3U = (m3uContent: string) => {
	const parser = new Parser();
	parser.push(m3uContent);
	parser.end();

	const channels = parser.manifest.segments.map((segment: any) => {
		// Используем регулярное выражение для извлечения названия канала из строки с тегами
		const match = segment.title.match(/,(.*)/);
		const name = match ? match[1].trim() : segment.title;

		return {
			name: name,
			url: segment.uri,
		};
	});

	return channels;
};
