import React, { useState } from 'react';

interface ChannelListProps {
	channels: { name: string; url: string }[];
	onChannelClick: (url: string) => void;
}

const ChannelList: React.FC<ChannelListProps> = ({
	channels,
	onChannelClick,
}) => {
	const [activeChannel, setActiveChannel] = useState<string | null>(null);

	const handleChannelClick = (url: string) => {
		setActiveChannel(url);
		onChannelClick(url);
	};

	return (
		<div>
			{channels.map((channel, index) => (
				<div
					key={index}
					className={`channel ${channel.url === activeChannel ? 'active' : ''}`}
					onClick={() => handleChannelClick(channel.url)}
				>
					{channel.name}
				</div>
			))}
		</div>
	);
};

export default ChannelList;
